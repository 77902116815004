.base-btn {
  transition: background 0.2s;
  text-align: center;
  color: #fff;
  font-weight: bold;
  border-radius: 12px;
  padding: 16px 32px;
}

.btn-gradient {
  background: linear-gradient(90.21deg, #00a3e9 0.22%, #28edc0 99.87%);
}

.btn-gradient-outline {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50px;
    border: 2px solid transparent;
    background: linear-gradient(90.21deg, #00a3e9 0.22%, #28edc0 99.87%)
      border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
}
