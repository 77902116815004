@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Theme Styles */
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
html {
  /* background-color: #1f2128; */
  background: #000610;
  overflow-x: hidden;
}
.theme-dk-blue {
  /* background-color: #1f2128; */
  background: #000610;
}
.lt-white {
  color: #fcfcf9;
}
h1,
h2,
h3,
h4 {
  font-family: 'Archivo', sans-serif;
}
a,
p {
  font-family: 'Inter', sans-serif;
}
h1 {
  font-size: 48px;
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 45px;
  }
}
.text-grey {
  color: #7f8596;
}
.text-dk-grey {
  color: #494e5b;
}
.accent-green {
  color: #30b28c;
}
section {
  padding: 2rem 0;
}
.arch {
  font-family: 'Archivo', sans-serif;
}
.mobile-show {
  display: block;
}

.modal-slim-overlay {
  background: rgba(31, 41, 55, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1500;
}

.modal-slim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  max-width: 100%;
  z-index: 1501;
}
.leftDesc a{
  text-decoration: underline !important;
  color: white !important;
}

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1.4s;
  transform-style: preserve-3d;
}

/* .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
} */

.flip-card-front,
.flip-card-back {
  /* box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2); */
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 1rem;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.htw-card {
  border-radius: 15px;
  border: 1px solid #6a5f5f;
  min-height: 350px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.htw-card1{
   /* border-radius: 15px;
  border: 1px solid #6a5f5f; */
  min-height: 350px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .htw-card {
    min-height: 450px;
  }
}