// Finer Tuning Styles

// Header Styles
img.logo {
  margin: 0 auto;
}
.nav-links {
  display: flex;
}
.nav-link {
  text-decoration: none;
  transition: 0.3s all;
}
.nav-icon {
  display: none;
}
.nav-active {
  border-bottom: 1px solid #fff;
  font-weight: 700 !important;
  padding-bottom: 0.5rem;
}
// Header Responsive
@media screen and (max-width: 1023px) {
    .nav-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        text-align: left;
        width: 100%;
        top: 80px;
        left: -120%;
        transition: 0.5s all;
      }
    
      .nav-links.active {
        left: -2.5rem;
        // background-color: #22252d;
        background: #000610;
        height: 105vh;
        z-index: 10;
        top: -3.75rem;
        padding-top: 6rem;
        padding-left: 2.5rem;
        width: 150%;
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        border-radius: 20px;
      }
            .nav-icon {
              display: flex;
              color: #fff;
              font-size: 1.5rem;
              z-index: 100;
              position: absolute;
            }
}
@media screen and (max-width: 1412px) {
  .navbar {
    position: relative;
  }
  // .nav-links {
  //   display: flex;
  //   flex-direction: column;
  //   position: absolute;
  //   text-align: left;
  //   width: 100%;
  //   top: 80px;
  //   left: -120%;
  //   transition: 0.5s all;
  // }
  // .nav-links.active {
  //   left: -2.5rem;
  //   // background-color: #22252d;
  //   background: #000610;
  //   height: 105vh;
  //   z-index: 10;
  //   top: -3.75rem;
  //   padding-top: 6rem;
  //   padding-left: 2.5rem;
  //   width: 150%;
  //   box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  //   border-radius: 20px;
  // }
  .nav-item {
    padding: 0.5rem 0;
  }
  
}
@media screen and (max-width: 500px) {
  .nav-links.active {
    width: 250%;
  }
}
@media screen and (max-width: 380px) {
  .nav-links {
    left: -200%;
  }
}
// Needs Border Radius
.header .mint {
  border: 2px solid;
  border-radius: 90px;
  padding: 12px 24px;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #28edc0, #00a3e9);
  text-decoration: none;
  font-size: 16px;
}
.header .wallet {
  border: 2px solid #777e90;
  border-radius: 90px;
  padding: 12px 24px;
  text-decoration: none;
  font-size: 16px;
}

// Homepage Styles
// VIP
.home-vip p {
  font-size: 16px;
  margin-bottom: 0;
}
.home-vip .learn {
  border: 2px solid #3772ff;
  border-radius: 12px;
  padding: 14px 30px;
  text-decoration: none;
}
.vip-slider .slick-dots {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2.5rem;
  width: auto;
  display: flex !important;
  align-items: start;
}
.vip-slider .slick-dots li {
  margin: 0 1rem;
}
.vip-slider .slick-dots li button:before {
  content: '';
  background-color: #7f8596;
  width: 30px;
  height: 8px;
  border-radius: 12px;
}
.vip-slider li.slick-active button:before {
  border: 3px solid #3772ff;
  background-color: transparent;
}
// Genesis
.genesis-slider {
  padding: 2rem 0;
}
.genesis-slider .slide-item {
  padding: 0 2rem;
}
.genesis-slider .slide-active {
  border-left: 1px solid #31343f;
  border-right: 1px solid #31343f;
}
.genesis-slider .content-slider .slide-item {
  padding-top: 5rem;
}
.genesis-slider .content-slider .slide-desc h2 {
  color: #fcfcf9;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
}
.genesis-slider .content-slider .slide-number {
  margin-top: 3rem;
}
.genesis-slider .content-slider .slide-desc.right p {
  margin-bottom: 3rem;
}
.genesis-slider .content-slider .slide-desc.right a {
  border: 2px solid #3772ff;
  background: none;
  border-radius: 12px;
  padding: 16px 24px;
  color: #ffffff;
}
.genesis-slider .mobile-slide-cta a {
  border: 2px solid #3772ff;
  background: none;
  border-radius: 12px;
  padding: 16px 24px;
  color: #ffffff;
}
.genesis-slider .slick-dots li {
  margin: 0 1rem;
}
.genesis-slider .slick-dots li button:before {
  content: '';
  background-color: #7f8596;
  width: 30px;
  height: 8px;
  border-radius: 12px;
}
.genesis-slider .mobile-slider {
  display: none;
}
.genesis-slider .mobile-slider h1 {
  margin-bottom: 1rem;
}
.genesis-slider .mobile-slider .slide-image {
  margin-top: 2rem;
}
.genesis-slider .slick-slide img {
  margin: 0 auto;
}
.genesis-slider .slick-dots {
  top: unset;
  bottom: -5rem;
}
@media screen and (max-width: 1175px) {
  .genesis-slider .mobile-slider {
    display: block;
  }
  .genesis-slider .desktop-slider {
    display: none;
  }
  .genesis-slider .slick-slide img {
    margin: unset;
  }
  .genesis-slider .slick-dots {
    bottom: -3rem;
    text-align: left;
    margin-left: -1rem;
  }
}
@media screen and (max-width: 600px) {
  .genesis-slider .slick-dots {
    bottom: -1rem;
  }
}
@media screen and (max-width: 350px) {
  .genesis-slider h1 {
    font-size: 35px;
  }
  .genesis-slider {
    margin: 0 -15px;
  }
}
// How
section.how {
  // background: #22252d;
  background: #000610;
  padding-bottom: 0;
}
.how h1 {
  font-size: 50px;
  line-height: 72px;
  margin-bottom: 5rem;
}
.how .how-list .list:first-of-type {
  border-bottom: 1px solid #31343f;
  padding-top: 1rem;
}
.how .how-list .list.highlight {
  background-color: #23242d;
}
.how .how-list .list-container {
  padding: 1rem;
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
}
.how .how-list .list-container p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 25px;
  line-height: 44px;
  margin-left: 1rem;
}
.how .metafans-lineup {
  margin-top: 4rem;
}
// HOW RESPONSIVE
@media screen and (max-width: 1200px) {
  .how h1 {
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 640px) {
  .how .how-list .list-container {
    max-width: 100%;
  }
}
@media screen and (max-width: 350px) {
  .how h1 {
    font-size: 40px;
  }
}
// Mint
section.mint .mint-list div.flex:first-of-type {
  padding-left: 0;
}
section.mint .mint-list div.flex:last-of-type {
  border-right: none;
}
// MINT RESPONSIVE
@media screen and (max-width: 1100px) {
  section.mint .mint-list div.flex:first-of-type {
    padding-left: 2rem;
  }
  section.mint .mint-list div.flex:last-of-type {
    border-bottom: none;
  }
  section.mint h1 {
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 640px) {
  section.mint .mint-list img {
    width: 5rem;
  }
  section.mint .mint-list div.flex {
    padding: 2rem 0;
  }
  section.mint .mint-list div.flex:first-of-type {
    padding-left: 0;
  }
  section.mint h1 {
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 500px) {
  .mint img.banner {
    height: 25vh;
    object-fit: cover;
  }
  section.mint h1 {
    font-size: 35px;
    line-height: 40px;
  }
}
@media screen and (max-width: 350px) {
  .genesis-slider .mobile-slide-cta a {
    padding: 10px 5px;
  }
}
// Countdown
.countdown .countdown-info p.info {
  max-width: 85%;
  margin-top: 1rem;
}
.countdown .launch-countdown h2 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}
.countdown .launch-countdown p.launch-date {
  border: 2px solid #6abb7c47;
  border-radius: 6px;
  margin-left: 1rem;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.countdown .countdown-meter h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
}
.countdown .countdown-meter p {
  color: #7f8596;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
}
.countdown .countdown-meter div {
  border-right: 1px solid #31343f;
  padding: 5px 2rem;
}
.countdown .countdown-meter div:last-of-type {
  border: none;
}
.footer-count-notes {
  border-top: 1px solid #31343f;
  margin-top: 1.5rem;
  padding-top: 1rem;
}
.footer-count-notes.final {
  border-top: none;
  margin-top: 0;
}
.footer-count-notes p {
  font-size: 14px;
  line-height: 24px;
}
.footer-count-notes a {
  background: #3772ff;
  border-radius: 12px;
  padding: 16px 24px;
}
.footer-count-notes a p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
}
.countdown .count-two p.launch-date {
  margin: 0;
}
.countdown .count-two .footer-count-notes p {
  font-size: 0.95rem;
  color: #fcfcf9;
  font-weight: 700;
}
// Newbs
.newbs h1 {
  margin-bottom: 5rem;
}
.newbs-box {
  padding: 16px 16px 24px;
  border: 1px solid #353945;
  border-radius: 16px;
}
.newbs img {
  width: 100%;
  max-height: 400px;
  object-fit: fill;
  margin-bottom: 2rem;
}
.newbs .newbs-container {
  max-width: 70%;
  margin: 0 auto;
}
.newbs .newbs-container a.mint p,
.newbs .newbs-container a.nfts p {
  padding: 12px 16px;
  background-color: #3772ff;
  border-radius: 90px;
  font-size: 14px;
  font-weight: 700;
}
.newbs .newbs-container a.learn p {
  padding: 12px 16px;
  background: linear-gradient(90.21deg, #00a3e9 0.22%, #28edc0 99.87%);
  border-radius: 90px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}
.newbs .newbs-container a {
  text-decoration: none;
}
// FAQs
.faqs {
  padding: 5rem 0;
}
.faqs .accordion__button {
  padding: 2rem 0;
  border-bottom: 1px solid #31343f;
}
.faqs .accordion p,
.faqs .accordion ul {
  padding: 1rem 0;
}
.faqs .accordion ul {
  list-style: disc;
  margin-left: 1rem;
}
.faqs .accordion ul li {
  padding: 5px 0;
}
.faqs .accordion__button[aria-expanded='true'] img {
  transform: rotate(45deg);
  animation-duration: 5s;
}
// FAQ Responsive
@media screen and (max-width: 650px) {
  .numbered-faq {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .accordion__button .open-img img {
    width: 2rem;
  }
  .accordion__button .open-img {
    justify-self: flex-end;
    text-align: right;
  }
}
// Signup
.home-signup {
  padding: 2rem;
}
.home-signup .signup-container .text-content {
  max-width: 50%;
  margin: 0 auto;
  padding: 3rem 0;
}
.home-signup .signup-container .social-icons div {
  margin: 0 10px;
}
.icon-assets img {
  position: absolute;
}
.icon-assets .coins {
  left: 0;
  top: 0;
  height: 100%;
  max-height: 300px;
}
.icon-assets .horse {
  right: -2rem;
  top: 0;
  height: 100%;
  max-height: 300px;
}
.home-signup.text-hidden p {
  display: none !important;
}
.signup-box p {
  text-align: left !important;
}
.signup-box div {
  background-color: transparent !important;
  padding-top: 0 !important;
}
.signup-box form {
  padding-top: 0 !important;
}
.klaviyo-form.klaviyo-form.klaviyo-form {
  padding: 0 !important;
}
.signup-box #text-signup div.needsclick {
  justify-content: start !important;
}
.home-signup input {
  background: rgb(97 103 125 / 50%) !important;
  border: 2px solid rgba(73, 78, 91, 0.15) !important;
  border-radius: 12px !important;
  padding: 0 20px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #fcfcf9 !important;
  height: 50px !important;
}
.home-signup div.phone-input {
  background: rgb(97 103 125 / 50%) !important;
  border: 2px solid rgba(73, 78, 91, 0.15) !important;
  border-radius: 12px !important;
  padding: 20px !important;
  min-width: 300px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #fcfcf9 !important;
}
.home-signup input::placeholder {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #fcfcf9 !important;
}
.home-signup label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcf9;
  margin-bottom: 10px;
}
.home-signup button.needsclick {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: 2px solid rgb(219 228 255 / 30%) !important;
  border-radius: 12px !important;
  padding: 16px 32px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 15px !important;
  margin-left: 20px !important;
}
.home-signup .signup-box [data-testid='form-row'].needsclick:last-child {
  margin-top: 1rem !important;
}
.home-signup
  #text-signup
  .needsclick.go4209603331.kl-private-reset-css-Xuajs1
  button.needsclick.kl-private-reset-css-Xuajs1 {
  display: none !important;
}
.home-signup #text-signup .needsclick.go4209603331.kl-private-reset-css-Xuajs1 {
  border: none !important;
}
// SIGNUP RESPONSIVE
@media screen and (max-width: 850px) {
  .icon-assets .coins {
    max-height: 150px;
  }
  .icon-assets .horse {
    max-height: 150px;
  }
  .home-signup .signup-container .text-content {
    max-width: 70%;
  }
}
@media screen and (max-width: 400px) {
  .home-signup .signup-box {
    width: 90% !important;
    margin: 0 auto;
  }
  .home-signup .signup-box [data-testid='form-row'].needsclick:last-child {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 1rem !important;

    [data-testid='form-component']:first-child {
      flex: auto !important;
      padding: 0 !important;
    }

    [data-testid='form-component']:last-child {
      margin: 10px 0 0 0 !important;
      padding: 0 0 40px !important;
    }
  }
  .home-signup .signup-box input {
    width: 100% !important;
  }
  .home-signup .signup-box button {
    width: 100% !important;
  }
  .icon-assets .coins {
    max-height: 100px;
  }
  .icon-assets .horse {
    max-height: 100px;
  }
  .home-signup .signup-container .social-icons div {
    margin: 0 5px;
  }
}

// Who We Are Styles
// Team
.about-team .team-view {
  border-bottom: 2px solid #31343f;
}
.about-team .team-select {
  padding: 0 1rem;
}
.react-tabs__tab.react-tabs__tab--selected {
  background: transparent;
  border: none;
  color: #fcfcf9;
  border-radius: 0;
  border-bottom: 3px solid #9f73e6;
}
.react-tabs__tab.react-tabs__tab--selected:focus:after {
  background: transparent !important;
}
.react-tabs__tab {
  padding: 0;
}
.about-team .team-list {
  margin-top: 3rem;
}
.about-team .team-list img {
  margin-bottom: 1rem;
}
// Team Responsive Styles
@media screen and (min-width: 550px) and (max-width: 768px) {
  .about-team .team-list img.headshot {
    height: 500px;
  }
}
@media screen and (max-width: 400px) {
  .about-team .team-select p {
    text-align: center;
    margin-top: -5px;
  }
}
@media screen and (max-width: 300px) {
  .team-select img {
    display: none;
  }
}
// Team Signup
.home-signup.team .signup-container {
  background: #1f3d8b;
  background-image: url(../assets/images/team-signup.jpg);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  position: relative;
  border-radius: 32px;
}

// NFT & Utilities Page Styles
// Benefits
.utility-collection .benefits-info .benefits-list .single-benefit {
  border-bottom: 1px solid #31343f;
  padding: 1rem 0;
}
// Benefits Responsive
@media screen and (max-width: 768px) {
  .utility-collection .benefits-info .benefits-list .single-benefit {
    flex-direction: column;
  }
}
// Collections
section.the-collections {
  // background: #22252d;
  // background: #000610;
}
.the-collections .collections-list .single-collection {
  padding: 2rem 0;
}
.the-collections .collections-list .single-collection p:last-of-type {
  margin-bottom: 3rem;
}
.the-collections .collections-list .single-collection a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 16px 32px;
  background: #3772ff;
  border-radius: 12px;
}
.the-collections .collection-stats {
  margin-bottom: 4rem !important;
}
.the-collections .collection-stats .stat-item h2 {
  margin-bottom: 0;
}
// Collections Responsive
@media screen and (max-width: 500px) {
  .the-collections .collections-list .single-collection img {
    height: 250px;
  }
  .the-collections h1 {
    font-size: 45px;
    line-height: 45px;
  }
}
@media screen and (max-width: 300px) {
  .the-collections .collections-list .single-collection a {
    padding: 16px 10px;
  }
}
// Join
.join-metafans img {
  height: fit-content;
}
// .join-metafans a {
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 24px;
//   color: #ffffff;
//   padding: 16px 32px;
//   background: #3772ff;
//   border-radius: 12px;
// }
.join-metafans .slick-dots {
  display: block;
  position: absolute;
  right: 0;
  bottom: -5rem;
  width: auto;
  display: flex !important;
  align-items: start;
}
.join-metafans .slick-dots li {
  margin: 0 1rem;
}
.join-metafans .slick-dots li button:before {
  content: '';
  background-color: #7f8596;
  width: 30px;
  height: 8px;
  border-radius: 12px;
}
.slick-dots li.slick-active button:before {
  border: 3px solid #3772ff;
  background-color: transparent;
}
.utilities-vert-slider img {
  border-radius: 30px;
  max-height: 50vh;
  object-fit: contain;
}
// JOIN RESPONSIVE
@media screen and (max-width: 768px) {
  .join-metafans img {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 300px) {
  .join-metafans a {
    padding: 15px 10px;
  }
}

// Mint Page
section.mint-landing {
  min-height: 65vh;
  margin-top: 3rem;
}
.mint-landing h1 {
  line-height: 3.25rem;
}
.mint-landing h1:last-of-type {
  margin-bottom: 1rem;
}
.mint-landing p {
  max-width: 75%;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.mint-landing .mint-ctas {
  margin: 3rem 0;
}
.mint-landing .connect a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 16px 32px;
  background: #3772ff;
  border-radius: 12px;
}
.mint-landing .learn a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 16px 32px;
  background: transparent;
  border: 2px solid #3772ff;
  border-radius: 12px;
}
// MINT PAGE RESPONSIVE
@media screen and (max-width: 640px) {
  .mint-landing .learn {
    margin-top: 3rem;
  }
  .mint-landing h1 {
    font-size: 35px;
    line-height: 45px;
  }
}

// Privacy Policy
.policy-text p {
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .privacy h1 {
    font-size: 35px;
  }
}

// Terms of Service
.terms-conditions p {
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .terms-conditions h1 {
    font-size: 35px;
  }
}

// Affiliate Page Styles
.affiliate .sign-up a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 16px 32px;
  background: #3772ff;
  border-radius: 12px;
}
.affiliate .login a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 16px 32px;
  background: transparent;
  border: 1px solid #3772ff;
  border-radius: 12px;
}
.affiliate p {
  max-width: 80%;
  margin: 0 auto;
}
.affiliate img {
  border-radius: 30px;
  margin-bottom: 3rem;
}
// Affiliate Responsive
@media screen and (max-width: 768px) {
  .affiliate h1 {
    font-size: 45px;
    line-height: 45px;
  }
}

// Tax Form Styles
.tax-form .needsclick.kl-private-reset-css-Xuajs1 {
  flex-direction: column !important;
}
.tax-form .needsclick.kl-private-reset-css-Xuajs1 .needsclick {
  width: 100%;
  padding-bottom: 3.5rem;
  margin: 0 auto;
  max-width: 400px;
}
.tax-form p {
  color: #fff;
}
.tax-form strong {
  color: #fff !important;
  font-family: Archivo, sans-serif;
}
.tax-form .needsclick.kl-private-reset-css-Xuajs1 .needsclick input {
  height: 100% !important;
  padding: 25px 20px !important;
  border-radius: 12px !important;
  background: rgba(49, 52, 63, 0.5) !important;
  border: 2px solid rgba(73, 78, 91, 0.15) !important;
  color: #fff !important;
  max-width: 400px !important;
  margin: 0 auto !important;
}
.tax-form
  .needsclick.kl-private-reset-css-Xuajs1
  .needsclick
  input::placeholder {
  color: #fff !important;
}
.tax-form .needsclick.kl-private-reset-css-Xuajs1 .needsclick button {
  padding: 20px 0 !important;
  margin-bottom: 0 !important;
  height: auto !important;
  max-width: 400px !important;
  text-align: center;
  flex-direction: row !important;
  align-self: center !important;
  background: #3772ff !important;
  border-radius: 12px !important;
  margin-top: 1rem;
}
.tax-form-text h1 {
  margin-top: 1rem;
}
.tax-form-text p {
  margin-bottom: -100px;
}
.tax-form .ql-editor span {
  color: #fff !important;
}

// Winners Clubhouse Landing
.winner-bg-slide {
  animation-duration: 30s;
  animation-name: slidedown;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes slidedown {
  from {
    margin-bottom: 0%;
    width: 300%;
  }
  to {
    margin-bottom: -10%;
    width: 100%;
  }
}
.clubhouse-landing .ellipse {
  animation: pulse 2s infinite;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-style: preserve-3d;
}
@keyframes spin { 
  0% {
    transform: rotateZ(-0.001turn) scale(1) rotateY(-0.001turn) rotateX(-0.001turn);
  }
  100% {
    transform: rotateZ(0.001turn) scale(1.01) rotateY(0.001turn) rotateX(0.001turn);
  }
}
.winners-slider .slick-dots {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -9rem;
}
@media screen and (max-width: 400px) {
  .winners-slider .slick-dots {
    bottom: -6rem;
  }
}
.winners-slider .slick-dots li {
  margin: 0 1rem;
}
.winners-slider .slick-dots li button:before {
  content: '';
  background-color: #7f8596;
  width: 30px;
  height: 8px;
  border-radius: 12px;
}
.winners-slider .slick-dots li.slick-active button:before {
  border: 3px solid #E45F35;
  background-color: transparent;
}
.winners-slider .slick-slide[aria-hidden="true"] {
  opacity: 0.08;
}
.winners-slider .slick-slide  {
  border-radius: 50px;
}
.winners-slider .slick-slide img {
  opacity: 0.3;
  border-radius: 50px;
}
.winners-slider .slick-slide.slick-current img {
  opacity: 1;
}
.clubhouse-landing .animate-pulse {
  position: relative !important;
  z-index: 0 !important;
}
.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
}
// Newsletter Opt-In Styles
.opt1 .klaviyo-form-Rqiv3k {
  padding: 3rem 0 !important;
}
.opt2 .needsclick.go2276882708.kl-private-reset-css-Xuajs1 {
  height: 49px !important;
  border-radius: 0 12px 12px 0 !important;
  margin-left: -5rem !important;
}

// Footer Styles
section.footer {
  padding: 2rem;
}
.footer .footer-container {
  padding-bottom: 2rem;
}
.footer .footer-nav ul {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  padding: 0;
}
.footer .footer-icons svg,
.footer .footer-icons img {
  color: #fff;
  width: 22px;
  height: 22px;
}
.footer .mint {
  border: 2px solid;
  border-radius: 90px;
  padding: 12px 24px;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #28edc0, #00a3e9);
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: #fcfcfd;
  margin-right: 1rem;
}
.footer .wallet {
  border: 2px solid #777e90;
  border-radius: 90px;
  padding: 12px 24px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: #fcfcfd;
}
.footer .footer-copyright {
  border-top: 1px solid #31343f;
  padding-top: 1rem;
}
.footer .footer-copyright .mini-nav div {
  margin: 0 10px;
}
.footer .footer-copyright .mini-nav a {
  text-decoration: none;
}
// FOOTER RESPONSIVE
@media screen and (max-width: 1024px) {
  .footer .footer-nav a {
    padding: 10px 0;
  }
  .footer .footer-nav a:first-of-type {
    padding-left: 0;
  }
  .footer .mint,
  .footer .wallet {
    margin-bottom: 1rem;
  }
}